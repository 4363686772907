import EmailIcon from "@material-design-icons/svg/outlined/mail.svg";
import classnames from "classnames";
import * as React from "react";

import LightTooltip from "~/components/core/Tooltip";
import AccountLayoutContext from "~/components/layouts/AccountLayout/AccountLayoutContext";
import { PARTNERS_EMAIL } from "~/constants/common";
import { trackEvent } from "~/utils/segment";
import { struct } from "~/utils/struct";

const AccountMenuNeedHelp: React.FC = (): JSX.Element => {
  const { sideMenuMinimized } = React.useContext(AccountLayoutContext);

  return (
    <footer className="pt-[32px] pb-[24px] border-t border-cream-300 px-[16px] whitespace-nowrap">
      {!sideMenuMinimized && (
        <h5 className="fieldLabel ml-[8px] mb-[4px]">Need help?</h5>
      )}
      <LightTooltip
        title={sideMenuMinimized ? "Contact us" : ""}
        placement="right"
        interactive
        enterTouchDelay={0}
      >
        <a
          className={classnames(
            "flex gap-[8px] items-center rounded-[8px] p-[8px] hover:bg-newGrowth-100 focus:bg-newGrowth-100",
            sideMenuMinimized && "mx-auto w-[40px]"
          )}
          href={`mailto:${PARTNERS_EMAIL}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackEvent("email_support", undefined)}
          data-testid={struct.adminDash.sideNav.contactUs}
        >
          <EmailIcon className="flex-shrink-0 h-[24px] w-[24px] fill-current text-navy-900" />
          {!sideMenuMinimized && (
            <span className="paragraph link !text-navy-900">Contact us</span>
          )}
        </a>
      </LightTooltip>
    </footer>
  );
};

export default AccountMenuNeedHelp;
