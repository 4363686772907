import ArrowIcon from "@material-design-icons/svg/filled/keyboard_arrow_left.svg";
import classnames from "classnames";
import * as React from "react";

import AccountLayoutContext from "~/components/layouts/AccountLayout/AccountLayoutContext";
import { struct } from "~/utils/struct";

export const AccountMenuChangeSize: React.FC = () => {
  const { onTriggerViewSideMenu, sideMenuMinimized } = React.useContext(
    AccountLayoutContext
  );

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      onTriggerViewSideMenu?.();
    }
  };

  return (
    <div
      role="button"
      tabIndex={1}
      onClick={onTriggerViewSideMenu}
      onKeyPress={handleKeyPress}
      aria-label={sideMenuMinimized ? "Expand side menu" : "Collapse side menu"}
      className={classnames(
        "fixed z-[1300] top-[78px] rounded-[8px] shadow-default w-[24px] h-[24px]",
        "bg-cream-100 hover:bg-cream-300 transition-opacity ease-out opacity-0 group-hover:opacity-100",
        sideMenuMinimized ? "left-[69px]" : "left-[260px]"
      )}
      data-testid={struct.adminDash.sideNav.minimize}
    >
      <ArrowIcon
        className={classnames(
          "w-[24px] h-[24px]",
          sideMenuMinimized && "rotate-180"
        )}
        aria-hidden
      />
    </div>
  );
};
