import * as React from "react";

import useHead from "~/components/layouts/common/useHead";
import { struct } from "~/utils/struct";

import AccountUser from "./AccountUser";

const AccountHeader: React.FC = () => {
  const head = useHead({ addAppPrefix: false });
  return (
    <header className="w-full flex-shrink-0 relative flex flex-col sm:flex-row items-start sm:items-center justify-between bg-transparent pb-[16px] pt-[16px] px-[24px] sm:px-[32px] z-[1050]">
      <h2
        data-testid={struct.adminDash.header.title}
        className="max-w-full h1 line-clamp-2 mt-[8px]"
      >
        {head}
      </h2>
      <AccountUser />
    </header>
  );
};

export default AccountHeader;
