import * as React from "react";

import sentry from "~/utils/sentry";

import ErrorBoundaryDefaultFallback, {
  ErrorBoundaryDefaultFallbackProps
} from "./ErrorBoundaryDefaultFallback";

export interface ErrorBoundaryProps {
  FallbackComponent: React.ComponentType<ErrorBoundaryDefaultFallbackProps>;
  children: React.ReactNode | React.ReactNode[];
}

interface State {
  error: Error | null;
}

const { captureException } = sentry();

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  State
> {
  state: State = {
    error: null
  };

  static defaultProps = {
    FallbackComponent: ErrorBoundaryDefaultFallback
  };

  static getDerivedStateFromError(error: Error): Partial<State> {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error(error, errorInfo);
    captureException(error, errorInfo);
  }

  render(): JSX.Element | React.ReactNode | React.ReactNode[] {
    const { children, FallbackComponent } = this.props;

    if (this.state.error) {
      return <FallbackComponent error={this.state.error} />;
    }

    return children;
  }
}
