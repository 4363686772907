import FiltonIcon from "@material-design-icons/svg/filled/filter_list.svg";
import IconButton from "@material-ui/core/IconButton";
import * as React from "react";

import AccountLayoutContext from "~/components/layouts/AccountLayout/AccountLayoutContext";

import LeftPanelOpenIcon from "../../../../assets/icons/left_panel_open.svg";
import { PearLauncher } from "./AccountMenuHeader";

const AccountAppBar: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { mobileView, onToggleFiltersDrawer: openFilter } = React.useContext(
    AccountLayoutContext
  );

  if (!mobileView) {
    return null;
  }

  return (
    <header className="z-[1300] fixed top-0 min-h-[48px] flex px-[16px] items-center w-full border-b bg-cream-100 border-cream-300 shadow-under">
      <PearLauncher />
      <h2 className="flex-grow h2 ml-[5px]">Pear Deck Tutor</h2>
      <div className="flex gap-[10px]">
        <IconButton onClick={onClick} color="inherit" size="small">
          <LeftPanelOpenIcon aria-hidden className="w-[24px] h-[24px]" />
        </IconButton>
        <IconButton onClick={openFilter} color="inherit" size="small">
          <FiltonIcon aria-hidden className="w-[24px] h-[24px]" />
        </IconButton>
      </div>
    </header>
  );
};

export default AccountAppBar;
