import { source, StructRecord } from "@tutorme/sitestruct/index.js";

import { MarkupFor } from "~/declarations/testids";

const disableTestIds = process.env.NEXT_PUBLIC_APP_ENV === "production";

function buildMarkup<T extends StructRecord[string]>(
  rec: T,
  path: string[] = []
): MarkupFor<T> {
  if (typeof rec === "string") {
    if (disableTestIds) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return undefined as any;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return path.join(".") as any;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const res: { [key: string]: any } = {};
  for (const key of Object.keys(rec)) {
    const isFunction = key.endsWith("ById");
    const cleanKey = key.replace(/(ById)$/, "");
    const value = rec[key] as StructRecord;
    res[cleanKey] = isFunction
      ? (id: number | string) =>
          buildMarkup(value, [...path, `${cleanKey}(${id})`])
      : buildMarkup(value, [...path, cleanKey]);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return res as any;
}

export const struct = buildMarkup(source);
