import * as Sentry from "@sentry/browser";
import * as SentryIntegrations from "@sentry/integrations";
import * as sentryTestkit from "sentry-testkit";

export default function(release = process.env.NEXT_PUBLIC_SENTRY_RELEASE) {
  const sentryOptions = {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release,
    maxBreadcrumbs: 50,
    attachStacktrace: true
  };

  // When we're developing locally
  if (process.env.NODE_ENV !== "production") {
    const { sentryTransport } = sentryTestkit();

    // Don't actually send the errors to Sentry
    sentryOptions.transport = sentryTransport;

    // Instead, dump the errors to the console
    sentryOptions.integrations = [
      new SentryIntegrations.Debug({
        // Trigger DevTools debugger instead of using console.log
        debugger: false
      })
    ];
  }

  Sentry.init(sentryOptions);

  return {
    Sentry,
    captureException: (error, context) => {
      Sentry.configureScope(scope => {
        if (error.message) {
          // De-duplication currently doesn't work correctly for SSR / browser errors
          // so we force deduplication by error message if it is present
          scope.setFingerprint([error.message]);
        }

        if (error.statusCode) {
          scope.setExtra("statusCode", error.statusCode);
        }

        if (context) {
          const { req, res, errorInfo } = context;

          if (res && res.statusCode) {
            scope.setExtra("statusCode", res.statusCode);
          }

          scope.setExtra("url", req.url);
          scope.setExtra("method", req.method);
          scope.setExtra("headers", req.headers);
          scope.setExtra("params", req.params);
          scope.setExtra("query", req.query);

          if (errorInfo) {
            Object.keys(errorInfo).forEach(key =>
              scope.setExtra(key, errorInfo[key])
            );
          }
        }
      });

      return Sentry.captureException(error);
    }
  };
}
